<template>
    <div class="infoprod pt-0 pb-0">
        <v-row justify="center"><small style="font-weight: bold;font-size: 18px;color:#0D47A1;">INFORMAÇÕES DA PRODUÇÃO</small></v-row>
        <v-row>
            <v-col cols="6">
                <div style="display: flex; align-items: center;">
                    <v-text-field density="compact" label="FUNCIONARIO" v-model="appStore.apontamento_atual.funcionario.nome" readonly style="font-weight: bold;width: 90%;"
                        id="input_user_shop" placeholder="Não Informado" persistent-placeholder></v-text-field>
                    <v-btn size="40" @click="ShowFuncionarios" class="mb-5 ml-2" style="width: 10%;font-size: 25px; min-width: 40px;"
                        color="secondary" append-icon="mdi-magnify"></v-btn>
                </div>
            </v-col>
            <v-col cols="6">
                <div style="display: flex; align-items: center;">
                    <v-text-field density="compact" label="OBSERVAÇÃO" v-model="appStore.apontamento_atual.observacao" readonly style="font-weight: bold;width: 80%;"
                        id="input_obs" placeholder="Não Informado" persistent-placeholder></v-text-field>
                   
                        <v-btn size="40" class="mb-5 ml-2" style="width: 10%;font-size: 25px; min-width: 40px;"
                        color="secondary" @click="ShowObs" append-icon="mdi-file-edit-outline"></v-btn>
                </div>
            </v-col>
            <v-col cols="6">
                <div style="display: flex; align-items: center;">
                    <v-text-field density="compact" label="ORDEM DE PRODUÇÃO" readonly  v-model="appStore.apontamento_atual.op"
                        style="font-weight: bold;width: 80%;" id="input_user_shop" placeholder="Não Informado"
                        persistent-placeholder></v-text-field>
                        
                    <v-btn size="40" class="mb-5 ml-2" @click="ShowOP" style="width: 10%;font-size: 25px; min-width: 40px;"
                        color="secondary" append-icon="mdi-magnify"></v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { useAppFuncionarios } from '../../stores/appFuncionarios';

import { useAppOrdemProducao } from '../../stores/appOrdemProducao';

import { useAppApontamento } from '../../stores/appApontamento';

import { useAppStore } from '../../stores/app';

export default {
    name: "infoprodApontamento",
    methods: {
        ShowFuncionarios() {
            useAppStore().view_apontamento_historico = false;
            useAppFuncionarios().showModal = true;
        },
        ShowOP(){
            useAppStore().view_apontamento_historico = false;
            useAppOrdemProducao().showModal = true;
        },
        ShowObs(){
            useAppStore().view_apontamento_historico = false;
            useAppApontamento().observacao_modal = useAppStore().apontamento_atual.observacao;
            useAppApontamento().showModalObs = true;
        }
    },
    computed: {
        appStore() {
            return useAppStore();
        },
        appApontamento() {
            return useAppApontamento();
        }
    }
}

</script>

<style></style>