<template>
    <v-dialog v-model="appApontamento.showModal" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <v-toolbar color="primary">
                <v-btn @click="appApontamento.showModal = false" color="secondary" variant="elevated"
                    prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>

                <v-toolbar-title style="font-size: 13px;font-weight: bold;">{{ appApontamento.titulo_modal }}</v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-card color="#EEEEEE">
                            <v-row aligin="center">
                                <v-col cols="6">
                                    <eventosApontamento class="mb-2" />
                                </v-col>
                                <v-col cols="6">
                                    <div class="infoprod pt-0 pb-0">
                                        <v-row justify="center"><small style="font-weight: bold;">INFORMAÇÕES DA
                                                PRODUÇÃO</small></v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <div style="display: flex; align-items: center;">
                                                    <v-text-field density="compact" v-model="appApontamento.resourceApontamento.nome_operador" label="FUNCIONARIO" readonly
                                                        style="font-weight: bold;width: 90%;" id="input_user_shop"
                                                        placeholder="Não Informado"
                                                        persistent-placeholder></v-text-field>
                                                    <v-btn size="40" @click="ShowFuncionarios" class="mb-5 ml-2"
                                                        style="width: 10%;font-size: 25px; min-width: 40px;"
                                                        color="secondary" append-icon="mdi-magnify"></v-btn>
                                                </div>
                                            </v-col>
                                            <v-col cols="6">
                                                <div style="display: flex; align-items: center;">
                                                    <v-text-field density="compact" v-model="appApontamento.resourceApontamento.observacao" label="OBSERVAÇÃO" readonly
                                                        style="font-weight: bold;width: 80%;" id="input_user_shop"
                                                        placeholder="Não Informado"
                                                        persistent-placeholder></v-text-field>
                                                
                                                        <v-btn size="40" class="mb-5 ml-2" @click="ShowObs"
                                                        style="width:  10%;font-size: 25px; min-width: 40px;"
                                                        color="secondary" append-icon="mdi-file-edit-outline"></v-btn>
                                                </div>
                                            </v-col>
                                            <v-col cols="6">
                                                <div style="display: flex; align-items: center;">
                                                    <v-text-field density="compact" v-model="appApontamento.resourceApontamento.ordem_de_producao" label="ORDEM DE PRODUÇÃO" readonly
                                                        style="font-weight: bold;width: 80%;" id="input_user_shop"
                                                        placeholder="Não Informado"
                                                        persistent-placeholder></v-text-field>

                                                    <v-btn size="40" class="mb-5 ml-2" @click="ShowOP"
                                                        style="width: 10%;font-size: 25px; min-width: 40px;"
                                                        color="secondary" append-icon="mdi-magnify"></v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import { useAppApontamento } from '../../../stores/appApontamento';

import eventosApontamento from '../eventosApontamento.vue';

import { useAppFuncionarios } from '../../../stores/appFuncionarios';

import { useAppOrdemProducao } from '../../../stores/appOrdemProducao';

import { useAppStore } from '../../../stores/app';

export default {
    name: "apontamento",
    data() {
        return {

        };
    },
    components: {
        eventosApontamento
    },
    computed: {
        appApontamento() {
            return useAppApontamento();
        }
    },
    methods: {
        ShowFuncionarios() {
            useAppStore().view_apontamento_historico = true;
            useAppFuncionarios().showModal = true;
           
        },
        ShowOP() {
            useAppStore().view_apontamento_historico = true;
            useAppOrdemProducao().showModal = true;
        },
        ShowObs () {
            useAppStore().view_apontamento_historico = true;
            useAppApontamento().showModalObs = true;
            useAppApontamento().observacao_modal = useAppApontamento().resourceApontamento.observacao;
        }
    }
};

</script>

<style></style>