import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "detalheapontamento" }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = { class: "p-filtro-selecionado" }
const _hoisted_4 = { style: {"background-color":"#37474f","display":"block"} }
const _hoisted_5 = { class: "d-flex align-center" }
const _hoisted_6 = { style: {"font-weight":"bold","font-size":"15px"} }
const _hoisted_7 = { class: "actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_tab = _resolveComponent("v-tab")
  const _component_v_tabs = _resolveComponent("v-tabs")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_list = _resolveComponent("v-list")
  const _component_v_menu = _resolveComponent("v-menu")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_e_column = _resolveComponent("e-column")
  const _component_e_columns = _resolveComponent("e-columns")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_ejs_grid = _resolveComponent("ejs-grid")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_tab_item = _resolveComponent("v-tab-item")
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_tabs, {
      modelValue: $data.tab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.tab) = $event)),
      "bg-color": "primary",
      color: "white",
      "slider-color": "secondary",
      "selected-class": "color-tab",
      style: {"flex-shrink":"1","min-height":"30px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, {
          value: "historico",
          style: {"width":"40%","min-width":"20%","max-width":"350px","height":"100%"}
        }, {
          default: _withCtx(() => [
            _createTextVNode("HISTÓRICO DE HOJE")
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_v_tabs_window, {
      modelValue: $data.tab,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.tab) = $event)),
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab_item, { value: "historico" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              id: "container-events-mp",
              cols: "12",
              class: "pt-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  xs: "12",
                  sm: "12",
                  md: "12",
                  id: "v-col-historico"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      color: "#EEEEEE",
                      class: "cardEvents",
                      rounded: "0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_toolbar, {
                          height: "60",
                          color: "#37474F"
                        }, {
                          append: _withCtx(() => [
                            _createVNode(_component_v_row, {
                              justify: "end",
                              class: "mt-3"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                  _createVNode(_component_v_btn, {
                                    onClick: $options.onShowHistorico,
                                    color: "#EEEEEE",
                                    class: "mb-5",
                                    variant: "flat",
                                    text: "",
                                    "prepend-icon": "mdi mdi-magnify"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" HISTÓRICOS ANTERIORES ")
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["onClick"]),
                                  _createVNode(_component_v_checkbox, {
                                    class: "pt-0 pb-0",
                                    label: "FALTANDO INFORMAÇÕES",
                                    modelValue: $data.ShowInfosFaltando,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.ShowInfosFaltando) = $event))
                                  }, null, 8 /* PROPS */, ["modelValue"]),
                                  _createVNode(_component_v_menu, null, {
                                    activator: _withCtx(({ props }) => [
                                      _createVNode(_component_v_btn, _mergeProps({
                                        class: "mb-5",
                                        icon: "mdi mdi-filter-menu-outline",
                                        color: "white"
                                      }, props), null, 16 /* FULL_PROPS */),
                                      _createElementVNode("p", _hoisted_3, _toDisplayString($data.FiltroSelecionado), 1 /* TEXT */)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list, null, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsFiltro, (item, index) => {
                                            return (_openBlock(), _createBlock(_component_v_list_item, {
                                              key: index,
                                              value: index,
                                              onClick: $event => ($data.FiltroSelecionado = item.title)
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                                              ]),
                                              _: 2 /* DYNAMIC */
                                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onClick"]))
                                          }), 128 /* KEYED_FRAGMENT */))
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_ejs_grid, {
                            dataSource: $options.appStore.apontamentos_historico,
                            allowPaging: true,
                            allowSorting: false,
                            allowFiltering: false,
                            enableHover: false,
                            enableVirtualization: false,
                            enableHeaderFocus: false,
                            rowDataBound: $options.onRowDataBound
                          }, {
                            ordem_producao: _withCtx(({ data }) => [
                              _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_v_btn, {
                                  variant: "text",
                                  size: "35",
                                  onClick: $event => ($options.ShowOrdemDeProducaoHistorico(data)),
                                  color: "secondary",
                                  class: "mr-2"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, {
                                      color: "white",
                                      size: "35"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("mdi-magnify")
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                                (data.ordem_de_producao)
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 0,
                                      variant: "text",
                                      size: "35",
                                      onClick: $event => ($options.ShowDetalheOS(data)),
                                      color: "secondary",
                                      class: "ml-2 mr-2"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, {
                                          color: "white",
                                          size: "35"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-folder-open")
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                                  : _createCommentVNode("v-if", true),
                                _createElementVNode("small", _hoisted_6, _toDisplayString(data.ordem_de_producao), 1 /* TEXT */)
                              ])
                            ]),
                            columnTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_v_btn, {
                                  block: "",
                                  size: "60",
                                  onClick: $event => ($options.ShowApontamento(data)),
                                  color: "secondary"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, { size: "35" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("mdi mdi-pencil")
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_e_columns, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_e_column, {
                                    headerText: "",
                                    field: "status",
                                    template: 'columnTemplate',
                                    width: "80",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "STATUS",
                                    field: "name",
                                    width: "80",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "STATUS",
                                    field: "status",
                                    width: "80",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "STATUS DA PRODUÇÃO",
                                    field: "tipo",
                                    width: "80",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "INICIO",
                                    width: "100",
                                    field: "dt_inicio",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "FIM",
                                    width: "100",
                                    field: "dt_fim",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "OS",
                                    width: "100",
                                    template: 'ordem_producao',
                                    field: "ordem_de_producao",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "TURNO",
                                    width: "100",
                                    field: "turno",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "MOTIVO DE PARADA",
                                    width: "100",
                                    field: "motivo_parada",
                                    textAlign: "Left"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "FUNCIONARIO",
                                    width: "100",
                                    field: "nome_operador",
                                    textAlign: "Left"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "OBSERVAÇÃO",
                                    width: "100",
                                    field: "observacao",
                                    textAlign: "Left"
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["dataSource", "rowDataBound"])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}