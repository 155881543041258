import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"background-color":"#37474f","display":"block"} }
const _hoisted_2 = { class: "actions" }
const _hoisted_3 = ["model"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_tab = _resolveComponent("v-tab")
  const _component_v_tabs = _resolveComponent("v-tabs")
  const _component_e_column = _resolveComponent("e-column")
  const _component_e_columns = _resolveComponent("e-columns")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_ejs_grid = _resolveComponent("ejs-grid")
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")
  const _component_v_card = _resolveComponent("v-card")
  const _component_ejs_pdfviewer = _resolveComponent("ejs-pdfviewer")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appStore.showModalObsDetalhes,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($options.appStore.showModalObsDetalhes) = $event)),
    transition: "dialog-bottom-transition",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: _cache[0] || (_cache[0] = $event => ($options.appStore.showModalObsDetalhes = false)),
                color: "secondary",
                variant: "elevated",
                "prepend-icon": "mdi-arrow-left-circle"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("VOLTAR")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_toolbar_title, { style: {"font-size":"16px","font-weight":"bold"} }, {
                default: _withCtx(() => [
                  _createTextVNode("ORDEM DE PRODUÇÃO")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_tabs, {
            modelValue: $data.tab,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.tab) = $event)),
            "bg-color": "primary",
            color: "white",
            "slider-color": "secondary",
            "selected-class": "color-tab",
            style: {"flex-shrink":"1","min-height":"30px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tab, {
                value: "anexos",
                style: {"width":"40%","min-width":"20%","max-width":"350px","height":"100%"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode("ANEXOS")
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_v_tabs_window, {
            modelValue: $data.tab,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.tab) = $event)),
            style: {"height":"100%","min-height":"550px","width":"100%"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ejs_grid, {
                  dataSource: $options.appOrdemProducao.resourceFiles,
                  allowPaging: true,
                  allowSorting: false,
                  allowFiltering: false,
                  enableHover: false,
                  enableVirtualization: false,
                  enableHeaderFocus: false,
                  rowDataBound: $options.onRowDataBound
                }, {
                  columnTemplate: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_v_btn, {
                        block: "",
                        size: "60",
                        onClick: $event => ($options.ShowFile(data)),
                        color: "secondary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { size: "35" }, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-open-in-new")
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_e_columns, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_e_column, {
                          headerText: "",
                          field: "arquivo",
                          template: 'columnTemplate',
                          width: "20",
                          textAlign: "Center"
                        }),
                        _createVNode(_component_e_column, {
                          headerText: "DESCRIÇÃO",
                          field: "descricao",
                          width: "80",
                          textAlign: "Left"
                        }),
                        _createVNode(_component_e_column, {
                          headerText: "FILE",
                          field: "arquivo",
                          width: "80",
                          textAlign: "Left"
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["dataSource", "rowDataBound"])
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_dialog, {
        fullscreen: "",
        modelValue: $data.showModalFile,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.showModalFile) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar, { color: "primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    onClick: _cache[3] || (_cache[3] = $event => ($data.showModalFile = false)),
                    color: "secondary",
                    variant: "elevated",
                    "prepend-icon": "mdi-arrow-left-circle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("VOLTAR")
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_toolbar_title, { style: {"font-size":"16px","font-weight":"bold"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($data.file_select.descricao), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_spacer)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_card_text, { class: "pt-0 pb-0 pl-0 pr-0" }, {
                default: _withCtx(() => [
                  ($data.showpdf)
                    ? (_openBlock(), _createBlock(_component_ejs_pdfviewer, {
                        key: 0,
                        id: "pdfViewer",
                        serviceUrl: $data.serviceUrl,
                        documentPath: $data.documentPath,
                        style: {"height":"100vh"}
                      }, null, 8 /* PROPS */, ["serviceUrl", "documentPath"]))
                    : (_openBlock(), _createBlock(_component_v_card, {
                        key: 1,
                        flat: "",
                        style: {"height":"80vh"},
                        class: "border-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { style: {"color":"white"} }, {
                            default: _withCtx(() => [
                              _createTextVNode("Visualizador 3D")
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_card_text, { style: {} }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                style: {"height":"70vh"},
                                model: $data.documentPath,
                                class: "online_3d_viewer border-bottom border-left border-right",
                                id: "viewer"
                              }, null, 8 /* PROPS */, _hoisted_3)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }))
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}