<template>
    <v-dialog v-model="appFuncionarios.showModal" transition="dialog-bottom-transition" fullscreen>
        <v-card >
            <v-toolbar color="primary" >
                <v-btn @click="appFuncionarios.showModal = false"  color="secondary" variant="elevated"
                    prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>

                <v-toolbar-title>FUNCIONARIOS</v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="pt-0 pb-0 mt-1 mb-0 pl-0 pr-0">
                        <ejs-grid :allowPaging='true' :dataSource="resourceFuncionarios" :recordClick="rowSelectedFunc" width="100%"
                            :allowSorting='true' :allowFiltering='true'>
                            <e-columns>
                                <e-column headerText='' field="foto" :template="'columnTemplate'" width='10' textAlign="Center"></e-column>
                                <e-column headerText='IDENTIFICAÇÃ0' field="identificacao" width='10' textAlign="Left"></e-column>
                                <e-column headerText='NOME' width='100' field="nome" textAlign="Left"></e-column>
                            </e-columns>
                            <template v-slot:columnTemplate="{ data }">
                                <div class="image">
                                    <v-avatar size="50" v-if="data.foto != null">
                                        <img style="width: 100%; height: auto;" :src="data.foto" />
                                    </v-avatar>
                                    <v-avatar color="secondary" size="50" v-else>
                                        <span class="text-h5">{{IniciaisFunc(data.nome)}}</span>
                                    </v-avatar>
                                </div>
                            </template>
                        </ejs-grid>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Sort } from '@syncfusion/ej2-vue-grids';

import { useAppFuncionarios } from '../../../stores/appFuncionarios';

import { useAppStore } from '../../../stores/app';
import { useAppApontamento } from '../../../stores/appApontamento';

export default {
    name: "funcionarios",
    components: {
        'ejs-grid': GridComponent,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective
    },
    provide: {
        grid: [Page, Edit, Toolbar, Sort]
    },
    data() {
        return {
        }
    },
    methods: {
        fotoTemplate(data) {
            console.log(data);
            return `<img src="${data.foto}" alt="Foto" style="width: 100%; height: auto;" />`;
        },
        IniciaisFunc(data) {
            return data.split(' ').map((n) => n[0]).join('');
        },
        rowSelectedFunc(args) {
            const json_update = {
                    operador : args.rowData.name
                }

                
            if (!useAppStore().view_apontamento_historico) {
                useAppStore().selecionar_funcionario(args.rowData);
                useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, json_update)
                useAppFuncionarios().showModal = false;
            }
            else {
                useAppApontamento().selecionar_funcionario(args.rowData);
                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, json_update)     
                useAppFuncionarios().showModal = false;
            }
        }
    },
    computed: {
        appFuncionarios() {
            return useAppFuncionarios();
        },
        resourceFuncionarios() {
            return useAppFuncionarios().resourceFuncionarios;
        }
    },
    mounted() {
        useAppFuncionarios().listar_funcionarios();
    },
}

</script>
