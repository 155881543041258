import { defineStore } from 'pinia'

interface MaquinaSelecionada {
  id: String | null,
  leitor: String | null,
  nome: String | null,
  operador_padrao: String | null,
  tipo_apontamento: String | null,
  apontamentos: Apontamentos[],
  disponibilidade_diaria: Number
}


interface Apontamentos {
  name: Number,
  nome_operador: String,
  recurso: String,
  desc_recurso: String,
  dt_inicio: String,
  dt_fim: String,
  ordem_de_producao: String,
  status: String,
  observacao: String,
  tipo: String,
  serial: String,
  motivo_de_parada: String,
  desc_motivo_parada: String,
  origem_apontamento: String,
  turno: String
}

interface Funcionario {
  name_entidade: string | null;
  nome: string | null;
}

interface ApontamentoAtual {
  name: Number | null;
  funcionario: Funcionario;
  op: String | null;
  observacao: String | null;
  dt_inicio: string | null;
  status: String | null;
  tipo: String | null;
  serial: String | null;
  turno: String | null;
}

export const useAppStore = defineStore({
  id: 'app',
  state: (): {
    company: String,
    maquina_sel: MaquinaSelecionada,
    apontamento_atual: ApontamentoAtual,
    apontamentos_historico: Apontamentos[],
    view_apontamento_historico: boolean,
    showModalHistorico: boolean,
    showModalObsDetalhes: boolean,
    showModalMotivoDeParada: boolean,
  } => ({
    company: "GRV Software",
    maquina_sel: {
      id: null,
      leitor: null,
      nome: null,
      operador_padrao: null,
      tipo_apontamento: null,
      apontamentos: [],
      disponibilidade_diaria: 0
    },
    apontamento_atual: {
      name: null,
      funcionario: {
        name_entidade: null,
        nome: null
      },
      op : null,
      observacao : null,
      dt_inicio : null,
      status : null,
      tipo : null,
      serial : null,
      turno: null
    },
    apontamentos_historico: [],
    view_apontamento_historico: false,
    showModalHistorico: false,
    showModalObsDetalhes: false,
    showModalMotivoDeParada: false
  }),
  getters: {

  },
  actions: {
    set_gauge(percent: number) {
      console.log('set_gauge', percent)
      this.maquina_sel.disponibilidade_diaria = percent;
    },

    reset_app() {
      this.apontamentos_historico = []

      this.maquina_sel = {
        id: null,
        leitor: null,
        nome: null,
        operador_padrao: null,
        tipo_apontamento: null,
        apontamentos: [],
        disponibilidade_diaria: 0
      }

      this.apontamento_atual = {
        name: null,
        funcionario: {
          name_entidade: null,
          nome: null
        },
        op : null,
        observacao : null,
        dt_inicio : null,
        status : null,
        tipo : null,
        serial : null,
        turno: null
      }
    },

    set_apontamento_realtime(apontamento: Apontamentos) {
      console.log('set_apontamento_realtime', apontamento)
      this.apontamento_atual.name = apontamento.name
      this.apontamento_atual.funcionario.nome = apontamento.nome_operador
      this.apontamento_atual.op = apontamento.ordem_de_producao
      this.apontamento_atual.observacao = apontamento.observacao
      this.apontamento_atual.dt_inicio = apontamento.dt_inicio
      this.apontamento_atual.status = apontamento.status
      this.apontamento_atual.tipo = apontamento.tipo
      this.apontamento_atual.serial = apontamento.serial
      this.apontamento_atual.turno = apontamento.turno
      console.log('apontamento_atual', this.apontamento_atual)
    },
    selecionar_op(op: any) {
      this.apontamento_atual.op = op.name
    },
    async selecionar_maquina(maquina: MaquinaSelecionada) {
      const disponibilidade_diaria = await frappe.db.get_value('Disponibilidade Diaria', `${maquina.id}: ${frappe.datetime.get_today()}`, 'percentual_producao')
      this.apontamentos_historico = []

      this.maquina_sel = maquina

      this.maquina_sel.disponibilidade_diaria = (typeof disponibilidade_diaria.message.percentual_producao === 'number') ? disponibilidade_diaria.message : 0;

      console.log('disponibiidade selecionar_maquina', this.maquina_sel.disponibilidade_diaria)

      if (maquina.apontamentos.length > 0) {
        this.set_apontamento_realtime(maquina.apontamentos[0])
        this.apontamentos_historico = maquina.apontamentos
      }

    },
    selecionar_funcionario(funcionario: Funcionario) {
      this.apontamento_atual.funcionario = funcionario
    },
    async get_all_filter_by_date_apontamento(dt_ini: any, dt_fim: any) {
      console.log('get_all_filter_by_date_apontamento', dt_ini, dt_fim);
      let apt = await frappe.call({
        method: 'nxlite.nx_producao.page.apontamento_iot.controller.get_all_filter_by_date_apontamento',
        args: {
          start_date: dt_ini,
          end_date: dt_fim
        },
      });
    
      const apontamentos: Apontamentos[] = [];

      apt.message.forEach((apontamento: any) => {
        apontamentos.push({
          name: apontamento.name,
          nome_operador: apontamento.nome_operador,
          recurso: apontamento.recurso,
          desc_recurso: apontamento.desc_recurso,
          dt_inicio: apontamento.dt_inicio,
          dt_fim: apontamento.dt_fim,
          ordem_de_producao: apontamento.ordem_de_producao,
          status: apontamento.status,
          observacao: apontamento.observacao,
          tipo: apontamento.tipo,
          serial: apontamento.serial,
          motivo_de_parada: apontamento.motivo_de_parada,
          desc_motivo_parada: apontamento.desc_motivo_parada,
          origem_apontamento: apontamento.origem_apontamento,
          turno: apontamento.turno
        });
      });

      console.log('apontamentos att', apontamentos);
      return apontamentos;
    },
    persist: {
      enabled: true
    }
  }
})