import { defineStore } from "pinia";

interface OrdemProducao {
  desc_cliente: string | null,
  criticidade: string | null,
  croqui_image: string | null,
  descricao: string | null,
  dt_entrega: string | null,
  estagio: string | null,
  item: string | null,
  observacao: string | null,
  name: string | null,
  status_op: string | null,
  quantidade: Number | null,
  dt_inicio: string | null,
}

interface FilesOrdemProducao {
  arquivo: String,
  descricao: String
}

export const useAppOrdemProducao = defineStore({
  id: "appOrdemProducao",
  state: (): {
    showModal: boolean;
    resourceOPs: OrdemProducao[];
    resourceFiles: FilesOrdemProducao[];
  } => ({
    showModal: false,
    resourceOPs: [],
    resourceFiles: [],
  }),
  getters: {

  },
  actions: {
    async listar_files_ordem_producao(name: string) {
      this.resourceFiles = [];

      const res = await frappe.call({
        method: 'nxlite.nx_producao.page.apontamento_iot.controller.get_files_ordem_de_producao',
        args: {
          op: name,
        }
      });

      res.message.forEach((file: any) => {
        this.resourceFiles.push({
          arquivo: file.arquivo,
          descricao: file.descricao
        });
      });

    },
    async listar_ops() {
      try {
        this.resourceOPs = [];

        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_ordem_de_producao",
          { limit: 20 });

        res.message.forEach((OrdemProducao: any) => {
          this.resourceOPs.push({
            desc_cliente: OrdemProducao.desc_cliente,
            criticidade: OrdemProducao.criticidade,
            croqui_image: OrdemProducao.croqui_image,
            descricao: OrdemProducao.descricao,
            dt_entrega: OrdemProducao.dt_entrega,
            estagio: OrdemProducao.estagio,
            item: OrdemProducao.item,
            observacao: OrdemProducao.observacao,
            name: OrdemProducao.name,
            status_op: OrdemProducao.status_op,
            quantidade: OrdemProducao.quantidade,
            dt_inicio: OrdemProducao.dt_inicio,
          });
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    update_ordem_de_producao_realtime(op_doc) {
      const prev_resource = this.resourceOPs;
      const new_data = {
        desc_cliente: op_doc.desc_cliente,
        criticidade: op_doc.criticidade,
        croqui_image: op_doc.croqui_image,
        descricao: op_doc.descricao,
        dt_entrega: op_doc.dt_entrega,
        estagio: op_doc.estagio,
        item: op_doc.item,
        observacao: op_doc.observacao,
        name: op_doc.name,
        status_op: op_doc.status_op,
        quantidade: op_doc.quantidade,
        dt_inicio: op_doc.dt_inicio,
      };
      const new_resource = prev_resource.map(data => data.name == op_doc.name ? new_data : data);
      this.resourceOPs = new_resource;
    },
    update_apontamento_realtime(data) {
      if (Object.keys(data).includes("ordem_de_producao")) {
        const { apontamento, ordem_de_producao } = data;
        const prev_resource = this.resourceOPs;
        const new_resource = prev_resource.map((data) => {
          if (data.name != ordem_de_producao.name) return data;
          if (!data.apontamentos)
            return { ...data, apontamentos: [apontamento] };
          if (data.apontamentos.length == 0)
            return { ...data, apontamentos: [apontamento] };
          const found_index = data.apontamentos.findIndex(
            (apt) => apt.name == apontamento.name
          );
          let new_apontamentos = data.apontamentos;
          if (found_index == -1) return { ...data, apontamentos: new_apontamentos.push(apontamento) };
          new_apontamentos[found_index] = apontamento;
          const new_data = { ...data, apontamentos: new_apontamentos };
          return new_data;
        });
        this.resourceOPs = new_resource;
        console.log(this.resourceOPs);
      }
    },
  },
  persist: {
    enabled: true,
  },
});
