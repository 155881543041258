import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app apontamento" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_navbar = _resolveComponent("navbar")
  const _component_statusMachine = _resolveComponent("statusMachine")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_detalheApontamento = _resolveComponent("detalheApontamento")
  const _component_funcionarios = _resolveComponent("funcionarios")
  const _component_ordemProducao = _resolveComponent("ordemProducao")
  const _component_apontamento = _resolveComponent("apontamento")
  const _component_observacao = _resolveComponent("observacao")
  const _component_historico = _resolveComponent("historico")
  const _component_detalhes_os = _resolveComponent("detalhes_os")
  const _component_motivos_parada = _resolveComponent("motivos_parada")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_main = _resolveComponent("v-main")
  const _component_v_app = _resolveComponent("v-app")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app, { style: {"background-color":"#ECEFF1"} }, {
      default: _withCtx(() => [
        _createVNode(_component_navbar),
        _createVNode(_component_v_main, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { fluid: "" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_statusMachine),
                  _createVNode(_component_v_divider, { class: "mt-1 mb-1 pb-1 pt-1" }),
                  _createVNode(_component_detalheApontamento)
                ]),
                _createVNode(_component_funcionarios),
                _createVNode(_component_ordemProducao),
                _createVNode(_component_apontamento),
                _createVNode(_component_observacao),
                _createVNode(_component_historico),
                _createVNode(_component_detalhes_os),
                _createVNode(_component_motivos_parada)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}