<template>
    <v-dialog v-model="appStore.showModalObsDetalhes" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <v-toolbar color="primary">
                <v-btn @click="appStore.showModalObsDetalhes = false" color="secondary" variant="elevated"
                    prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>

                <v-toolbar-title style="font-size: 16px;font-weight: bold;">ORDEM DE PRODUÇÃO</v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>

            <v-tabs v-model="tab" bg-color="primary" color="white" slider-color="secondary" selected-class="color-tab"
                style="flex-shrink: 1; min-height: 30px">
                <v-tab value="anexos" style="width: 40%; min-width: 20%; max-width: 350px; height: 100%">ANEXOS</v-tab>
            </v-tabs>
            <v-tabs-window v-model="tab" style="height: 100%; min-height: 550px; width: 100%">
                <div style="background-color: #37474f; display: block">
                    <ejs-grid :dataSource="appOrdemProducao.resourceFiles" :allowPaging="true" :allowSorting="false"
                        :allowFiltering="false" :enableHover='false' :enableVirtualization='false'
                        :enableHeaderFocus='false' :rowDataBound="onRowDataBound">
                        <e-columns>
                            <e-column headerText="" field="arquivo" :template="'columnTemplate'" width="20"
                                textAlign="Center"></e-column>
                            <e-column headerText="DESCRIÇÃO" field="descricao" width="80" textAlign="Left"></e-column>
                            <e-column headerText="FILE" field="arquivo" width="80" textAlign="Left"></e-column>

                        </e-columns>
                        <template v-slot:columnTemplate="{ data }">
                            <div class="actions">
                                <v-btn block size="60" @click="ShowFile(data)" color="secondary">
                                    <v-icon size="35">mdi-open-in-new</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </ejs-grid>
                </div>
            </v-tabs-window>
        </v-card>
        <v-dialog fullscreen v-model="showModalFile">
            <v-card color="primary">
                <v-toolbar color="primary">
                    <v-btn @click="showModalFile = false" color="secondary" variant="elevated"
                        prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>

                    <v-toolbar-title style="font-size: 16px;font-weight: bold;">{{ file_select.descricao
                        }}</v-toolbar-title>

                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="pt-0 pb-0 pl-0 pr-0">
                    <ejs-pdfviewer id="pdfViewer" v-if="showpdf" :serviceUrl="serviceUrl" :documentPath="documentPath"
                        style="height:100vh">
                    </ejs-pdfviewer>

                    <v-card flat v-else style="height: 80vh" class="border-0">
                        <v-card-title style="color:white">Visualizador 3D</v-card-title>
                        <v-card-text style="">
                            <div style="height: 70vh;" :model="documentPath" class="online_3d_viewer border-bottom border-left border-right"
                                id="viewer">
                            </div>
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>

import { useAppStore } from '../../../stores/app';

import { useAppOrdemProducao } from '../../../stores/appOrdemProducao';

import * as OV from 'online-3d-viewer';

import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Toolbar as GridToolbar,
    Edit,
    Sort,
} from "@syncfusion/ej2-vue-grids";

import {
    PdfViewerComponent, Toolbar,
    TextSearch

} from '@syncfusion/ej2-vue-pdfviewer';


export default {
    name: 'detalhes_os',
    components: {
        "ejs-pdfviewer": PdfViewerComponent,
        "ejs-grid": GridComponent,
        "e-columns": ColumnsDirective,
        "e-column": ColumnDirective,
    },
    provide: {
        PdfViewer: [
            TextSearch, Toolbar],
        grid: [Page, Edit, GridToolbar, Sort],
    },
    computed: {
        appStore() {
            return useAppStore();
        },
        appOrdemProducao() {
            return useAppOrdemProducao();
        }
    },
    watch: {
        file_select(new_value) {
            this.documentPath = window.location.origin + new_value.arquivo
        }
    },
    data() {
        return {
            tab: 'anexos',
            file_select: {
                file: null,
                descricao: null
            },
            showModalFile: false,
            serviceUrl: "https://services.syncfusion.com/vue/production/api/pdfviewer",
            documentPath: "",
            showpdf: false
        }
    },
    methods: {
        render_3d_viewer() {
            let parentDiv = document.getElementById('viewer');

            OV.Init3DViewerElements()
        },
        onRowDataBound(args) {
            args.row.style.backgroundColor = '#78909C';
        },
        ShowFile(data) {
            this.file_select = data;
            this.documentPath = window.location.origin + data.arquivo;

            console.log(data.arquivo)

            this.showpdf = data.arquivo.toLowerCase().endsWith('.pdf') ? true : false;

            this.showModalFile = true;

            if (!this.showpdf) {
                setTimeout(() => {
                    this.render_3d_viewer();
                }, 1000);
            }
        }

    }
}



</script>


<style>
.border-bottom {
  border-bottom: 2px solid #000; /* Ajuste a cor e a espessura conforme necessário */
}

.border-left {
  border-left: 2px solid #000; /* Ajuste a cor e a espessura conforme necessário */
}

.border-right {
  border-right: 2px solid #000; /* Ajuste a cor e a espessura conforme necessário */
}

</style>