import { defineStore } from 'pinia'

interface MotivoDeParada {
    name : Number,
    descricao : String,
    planejada : Boolean,
    setup : Boolean
}

export const useAppMotivoDeParada = defineStore({
    id: 'appMotivosdeParada',
    state: (): {
        resourceMotivosDeParada : MotivoDeParada[],
        apontamento_name : Number | null, 
    } => ({
        resourceMotivosDeParada : [],
        apontamento_name : null
    }),
    actions: {
        async listar_motivos_de_parada() {
            try {
                this.resourceMotivosDeParada = [];

                const res = await frappe.call(
                  "nxlite.nx_producao.page.apontamento_iot.controller.get_all_motivos_de_parada");
        
                res.message.forEach((motivos_de_parada: any) => {
                  this.resourceMotivosDeParada.push({
                    name: motivos_de_parada.name,
                    descricao: motivos_de_parada.descricao,
                    planejada: motivos_de_parada.planejada,
                    setup: motivos_de_parada.setup
                  });
                });

                console.log(this.resourceMotivosDeParada);
            }
            catch (error) {
                throw new Error(error);
            }
        } 
    }
})
