export function formatDate(input: string): string {
  try {
    const date = new Date(input);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  catch (error) {
    throw new Error(error);
  }

}

export function elapsedSecondsToTimeStr(elapsedSeconds: number) {
  const hours = String(Math.floor(elapsedSeconds / 3600)).padStart(2, '0')
  const minutes = String(Math.floor(elapsedSeconds % 3600 / 60)).padStart(2, '0')
  const seconds = String(Math.floor(elapsedSeconds % 60)).padStart(2, '0')

  let resposta = { tempo: "" }

  resposta.tempo = `${hours}:${minutes}:${seconds}`;

  return resposta
}

export function CalculaTempoMaquina(DataRequisicao: any) {
  const GetdataAtual = new Date();

  return (elapsedSecondsToTimeStr((GetdataAtual - DataRequisicao) / 1000));
}

export function formatDateSearch(data: string): string {
  try {
    const date = new Date(data);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${year}-${month}-${day}`; 
  }
  catch (error) {
    throw new Error(error);
  }

}