import { defineStore } from 'pinia'


import { useAppStore } from './app'
interface Apontamento {
    name: Number | null,
    nome_operador: String | null,
    recurso: String | null,
    desc_recurso: String | null,
    dt_inicio: String | null,
    dt_fim: String | null,
    ordem_de_producao: String | null,
    status: String | null,
    observacao: String | null
  }

  interface Apontamentos {
    name: Number;
    nome_operador: String;
    recurso: String;
    desc_recurso: String;
    dt_inicio: String;
    dt_fim: String;
    ordem_de_producao: String;
    status: String;
    observacao: String;
    tipo : String;
    serial : String;
    motivo_de_parada : String;
    desc_motivo_parada : String;
    origem_apontamento : String;
    turno : String;
  }

export const useAppApontamento = defineStore({
    id: 'appApontamento',
    state: (): {
        showModal : boolean,
        titulo_modal : string,
        resourceApontamento : Apontamento,
        showModalObs : boolean,
    } => ({
        showModal : false,
        titulo_modal : '',
        resourceApontamento : {
            name : null,
            nome_operador : null,
            recurso : null,
            desc_recurso : null,
            dt_inicio : null,
            dt_fim : null,
            ordem_de_producao : null,
            status : null,
            observacao : null
        },
        showModalObs : false
    }),
    getters: {
 
    },
    actions: {
        set_apontamento(apontamento: Apontamento) {
            this.resourceApontamento = apontamento
        },
        selecionar_funcionario(funcionario: any) {
            this.resourceApontamento.nome_operador = funcionario.nome
        },
        async update_apontamento(name: any, data : any) {
            let apt = await frappe.call({
                method: 'nxlite.nx_producao.page.apontamento_iot.controller.update_apontamento',
                args: {
                    apt_name: name,
                    changes: data
                },
                callback: function(response : any) {
                    console.log(useAppStore().apontamentos_historico)
                    if (response.message.name == useAppStore().apontamentos_historico) {
                      console.log(response.message);
                    }
                }
            });
            return apt
        },
        async abrir_apontamento(recurso : String) {
            const res = await frappe.call(
                "nxlite.nx_producao.page.apontamento_iot.controller.criar_novo_apontamento",
                { recurso: recurso }
              );

              if (res.message.length) {
                const apontamentos: Apontamentos[] = [];

                if (Array.isArray(res.message[0].apontamentos)) {
                    res.message[0].apontamentos.forEach((apontamento: Apontamentos) => {
                    
                      apontamentos.push({
                        name: apontamento.name,
                        nome_operador: apontamento.nome_operador,
                        recurso: apontamento.recurso,
                        desc_recurso: apontamento.desc_recurso,
                        dt_inicio: apontamento.dt_inicio,
                        dt_fim: apontamento.dt_fim,
                        ordem_de_producao: apontamento.ordem_de_producao,
                        status: apontamento.status,
                        observacao : apontamento.observacao,
                        tipo : apontamento.tipo,
                        serial : apontamento.serial,
                        motivo_de_parada : apontamento.motivo_de_parada,
                        desc_motivo_parada : apontamento.desc_motivo_parada,
                        origem_apontamento : apontamento.origem_apontamento,
                        turno : apontamento.turno
        
                      });
                    });
                  } else {
                    console.error("maquina.apontamentos não é um array");
                  }

                useAppStore().maquina_sel.id = res.message[0].name
                useAppStore().maquina_sel.leitor = res.message[0].leitor
                useAppStore().maquina_sel.nome = res.message[0].nome
                useAppStore().maquina_sel.operador_padrao = res.message[0].operador_padrao
                useAppStore().maquina_sel.tipo_apontamento = res.message[0].tipo_apontamento
                useAppStore().maquina_sel.apontamentos = apontamentos
                

                useAppStore().set_apontamento_realtime(useAppStore().maquina_sel.apontamentos[0])
                useAppStore().apontamentos_historico = apontamentos

                console.log('maquina_sel new', useAppStore().maquina_sel)
              }
        }

    }
    })