import { createApp } from "vue";
import { createVuetify } from "vuetify";

import { createPinia } from "pinia";

import { registerLicense } from "@syncfusion/ej2-base";

import piniaPersist from "pinia-plugin-persist";

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import App from "../vue/apontamento-iot/app.vue";
import { useAppStore } from "../vue/apontamento-iot/stores/app";
var vuetify;
class ApontamentoIot {
  constructor({ wrapper, app_apontamento }) {
    try {
      this.$wrapper = $(wrapper);

      console.log("wrapper", wrapper);

      const app = createApp(App);

      const Lighttheme = {
        dark: false,
        variables: {},
        colors: {
          primary: "#121935",
          secondary: "#00acc1",
          cards: "#EEEEEE",
          bcinputs: "#FFFFFF",
        },
      };

      vuetify = createVuetify({
        components,
        directives,
        theme: {
          themes: {
            light: Lighttheme,
          },
        },
      });

      const pinia = createPinia();

      pinia.use(piniaPersist);

      SetVueGlobals(app);

      registerLicense(
        "Ngo9BigBOggjHTQxAR8/V1NCaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjUX9acH1XR2BZVEZ1Xw=="
      );

      app.use(vuetify).use(pinia).mount(this.$wrapper.get(0));
    } catch (e) {
      console.error(e);
    }
  }
}

frappe.provide("frappe.iot");
frappe.iot.ApontamentoIot = ApontamentoIot;

export default ApontamentoIot;
