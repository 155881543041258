<template>
    <v-app-bar color="primary" class="pt-0 pb-0" height="50">
      <small class="ml-2" style="font-size: 18px; color: #00acc1;font-weight: bold;">{{
        appStore.company
      }}</small>

      <v-spacer></v-spacer>
      <small class="mr-4" style="font-size: 16px; color: #00acc1;font-weight: bold;">VERSAO : 1.0.0.9</small>


      <v-btn color="#D50000" variant="elevated" @click="onlogout" prepend-icon="mdi-exit-to-app" >logout</v-btn>

    </v-app-bar>
</template>


<script>
import { useAppStore } from "../stores/app";


export default {
  name: "appNavbar",
  data() {
    return {
      count: 0,
    };
  },
  computed: {

    appStore() {
      return useAppStore();
    },
  },
  mounted() {},
  methods: {
    TesteRouter() {
      this.$router.push({ name: "/app/operador-apontamento" });
    },
    onlogout() {
      useAppStore().reset_app()
    },
  },
};
</script>

